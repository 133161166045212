import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useMediaQuery } from "react-responsive";

import "react-datepicker/dist/react-datepicker.css";
import "./Portfolio.css";

import CalendarIcon from "../../assets/images/portfolio/calendar.svg";
import CarbonIcon from "../../assets/images/portfolio/carbon.svg";
import DeliveredIcon from "../../assets/images/portfolio/delivered.svg";
import EffciencyIcon from "../../assets/images/portfolio/effciency.svg";
import ElectricityIcon from "../../assets/images/portfolio/electricity.svg";
import { ReactComponent as GreaterSignIcon } from "../../assets/images/portfolio/greaterSign.svg";
import { ReactComponent as LessSignIcon } from "../../assets/images/portfolio/lessSign.svg";
import PeakIcon from "../../assets/images/portfolio/peak.svg";
import WaterIcon from "../../assets/images/portfolio/water.svg";

import config from "../../config/config";
import ChartOne from "./Chart/ChartOne";
import ChartTwo from "./Chart/ChartTwo";
import SpaceTempatureChart from "./Chart/SpaceTempatureChart";
import PreChartIndex from "./PreMonthChart";

import { connect, useDispatch } from "react-redux";
import { fetchDataCount } from "../../store/actions/dataAction";

function Index(props) {
  const getCurrentSite = config.getCurrentSite;
  const dispatch = useDispatch(); // Use dispatch hook

  // modal
  const [showSiteChanged, setShowSiteChanged] = useState(false);
  const [ErrorMaxSitesSelected, setErrorMaxSitesSelected] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 800 });

  const [selectedSites, setSelectedSites] = useState(() => {
    const storedSelectedSites = JSON.parse(
      localStorage.getItem("selectedSites")
    );
    return storedSelectedSites && storedSelectedSites.length > 0
      ? storedSelectedSites
      : [];
  });

  const [selectedSiteNames, setSelectedSiteNames] = useState(() => {
    const storedSelectedSiteNames = JSON.parse(
      localStorage.getItem("selectedSiteNames")
    );
    return storedSelectedSiteNames && storedSelectedSiteNames.length > 0
      ? storedSelectedSiteNames
      : [];
  });

  useEffect(() => {
    if (selectedSites.length == 0 && selectedSiteNames.length == 0) {
      setShowSiteChanged(true);
    }
  }, []);

  let selectedDateMethod = localStorage.getItem("selectedDateMethod");
  let selectedDateLocal = localStorage.getItem("selectedDate");

  const [previousDate, setPreviousDate] = useState(
    selectedDateMethod !== "Month" || selectedDateMethod !== "Year"
      ? "Month"
      : selectedDateMethod
  );

  const [previosCalendar, setPreviousCalendar] = useState({
    from_date: moment(new Date()).format("YYYY-MM-DD"),
    to_date: moment(new Date()).format("YYYY-MM-DD"),
  });

  const [selectdDatePicker, setSelectedDatePicker] = useState(
    selectedDateLocal ? new Date(selectedDateLocal) : new Date()
  );

  let carbonEmissionRate = "";
  props.AuthDetail.company.forEach((comp) => {
    if (comp.name === getCurrentSite()) {
      carbonEmissionRate = comp.carbon_emission_rate;
    }
  });

  const [headerData, setHeaderData] = useState([
    {
      title: "Peak cooling",
      value: "-",
      image: PeakIcon,
      sign: "RT",
      obj_title: "peak_cooling",
    },
    {
      title: "Cooling delivered",
      value: "-",
      image: DeliveredIcon,
      sign: "RTH",
      obj_title: "cooling_delivered",
    },
    {
      title: "Electricity used",
      value: "-",
      image: ElectricityIcon,
      sign: "kWh",
      obj_title: "electricity_used",
    },
    {
      title: "Water used",
      value: "-",
      image: WaterIcon,
      sign: "m",
      obj_title: "water_used",
    },
    {
      title: "Efficiency",
      value: "-",
      image: EffciencyIcon,
      sign: "kW/RT",
      obj_title: "efficiency",
    },
    {
      title: "Carbon emissions",
      value: "-",
      image: CarbonIcon,
      sign: "MT",
      obj_title: "carbon_emission",
      tooltip_title: "Carbon multiplier",
      tooltip: `kWh x ${carbonEmissionRate}`,
    },
  ]);

  let theme = localStorage.getItem("theme")
    ? localStorage.getItem("theme")
    : "light";
  // add custom css for dark theme tooltip
  if (theme == "dark") {
    var styles = `
      .tooltip-arrow::before, .tooltip-arrow::before {
        border-bottom-color: #C4C4C4 !important;
      }
    `;
    var styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  }

  // Handler for checkbox toggle
  const handleCheckboxChange = (item) => {
    const isTitleSelected = selectedSites.includes(item.title);
    const isNameSelected = selectedSiteNames.includes(item.name);

    if (isTitleSelected && isNameSelected) {
      // Remove the site if it's already selected
      const updatedSites = selectedSites.filter((id) => id !== item.title);
      const updatedSiteNames = selectedSiteNames.filter(
        (name) => name !== item.name
      );
      setSelectedSites(updatedSites);
      setSelectedSiteNames(updatedSiteNames);

      // Save to localStorage
      localStorage.setItem("selectedSites", JSON.stringify(updatedSites));
      localStorage.setItem(
        "selectedSiteNames",
        JSON.stringify(updatedSiteNames)
      );
    } else {
      // Add the site if it's not already selected
      const updatedSites = [...selectedSites, item.title];
      const updatedSiteNames = [...selectedSiteNames, item.name];
      setSelectedSites(updatedSites);
      setSelectedSiteNames(updatedSiteNames);

      // Save to localStorage
      localStorage.setItem("selectedSites", JSON.stringify(updatedSites));
      localStorage.setItem(
        "selectedSiteNames",
        JSON.stringify(updatedSiteNames)
      );
    }
  };

  const handleConfirm = () => {
    if (selectedSiteNames.length === 0) {
      setErrorMaxSitesSelected(true); // Show error message if no site is selected
      return; // Exit the function
    }

    let from_date = moment(selectdDatePicker).format("YYYY-MM-DD");
    let to_date = moment(selectdDatePicker).format("YYYY-MM-DD");

    if (previousDate === "Month") {
      from_date = moment(selectdDatePicker)
        .startOf("month")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker).endOf("month").format("YYYY-MM-DD");
    } else if (previousDate === "Year") {
      from_date = moment(selectdDatePicker)
        .startOf("year")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker).endOf("year").format("YYYY-MM-DD");
    }

    setPreviousCalendar({
      from_date,
      to_date,
    });

    // Save the selected sites to localStorage
    localStorage.setItem(
      "selectedSiteNames",
      JSON.stringify(selectedSiteNames)
    );

    // Hide error if sites are selected
    setErrorMaxSitesSelected(false);

    // Fetch data based on the selected sites
    dispatch(fetchDataCount(from_date, to_date, selectedSiteNames));
    setShowSiteChanged(false); // Close the modal
  };

  const onDateRangeChosen = (date) => {
    let from_ts = moment(date).format("YYYY-MM-DD");
    let to_date = moment(date).format("YYYY-MM-DD");

    if (previousDate == "Month") {
      from_ts = moment(date).startOf("month").format("YYYY-MM-DD");
      to_date = moment(date).endOf("month").format("YYYY-MM-DD");
    } else if (previousDate == "Year") {
      from_ts = moment(date).startOf("year").format("YYYY-MM-DD");
      to_date = moment(date).endOf("year").format("YYYY-MM-DD");
    }
    localStorage.setItem("selectedDate", date);
    setSelectedDatePicker(date);
    dispatch(fetchDataCount(from_ts, to_date, selectedSiteNames));
  };

  useEffect(() => {
    let from_date = moment(selectdDatePicker).format("YYYY-MM-DD");
    let to_date = moment(selectdDatePicker).format("YYYY-MM-DD");

    if (previousDate == "Month") {
      from_date = moment(selectdDatePicker)
        .startOf("month")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker).endOf("month").format("YYYY-MM-DD");
    } else if (previousDate == "Year") {
      from_date = moment(selectdDatePicker)
        .startOf("year")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker).endOf("year").format("YYYY-MM-DD");
    }

    setPreviousCalendar({
      from_date,
      to_date,
    });
    dispatch(fetchDataCount(from_date, to_date, selectedSiteNames));
  }, [previousDate]);

  const CustomCalendar = React.forwardRef((props, ref) => {
    return (
      <div ref={ref} className={`calendar ${theme}`}>
        <div onClick={subtractDate} className="sign">
          <LessSignIcon />
          {/* <img src={LessSignIcon} /> */}
        </div>
        <div style={{ width: "90%" }}>
          <div
            onClick={props.onClick}
            style={{
              fontSize: 14,
              color: "#373737",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={CalendarIcon} />
            <span style={{ marginLeft: 10 }}>
              {previousDate === "Month"
                ? moment(props.value).startOf("month").format(" MMMM ")
                : previousDate === "Year"
                ? moment(props.value).startOf("year").format("YYYY")
                : null}
            </span>
          </div>
        </div>
        <div>
          <div onClick={nextDate} className="sign">
            <GreaterSignIcon />
          </div>
        </div>
      </div>
    );
  });

  const Card = (data) => {
    const { title, value, image, sign, obj_title } = data;
    return (
      <div className="card">
        <div className="title">{title}</div>
        <div>
          <img src={image} />
          {}
        </div>
        <div>
          <span className="count-porto">
            {!props.loading
              ? props.data_count[obj_title] == ""
                ? "-"
                : props.data_count[obj_title] == "-"
                ? "-"
                : obj_title === "electricity_used"
                ? Number(parseInt(props.data_count[obj_title])).toLocaleString()
                : Number(props.data_count[obj_title]).toLocaleString()
              : "-"}
          </span>

          <span className="sign-porto">
            {sign} {sign === "m" && <sup>3</sup>}
          </span>
        </div>
      </div>
    );
  };

  const CardWithToolTip = (data) => {
    const { title, value, image, sign, obj_title, tooltip_title, tooltip } =
      data;
    return (
      <div className="card">
        <div className="title">{title}</div>
        <div>
          <img src={image} />
          {}
        </div>
        <OverlayTrigger
          key="tooltip-temp"
          placement="bottom"
          overlay={
            <Tooltip
              id={`tooltip-temp`}
              className={`custom-tooltip-data${
                theme === "light" ? "" : "-dark"
              }`}
            >
              <p
                style={{
                  fontSize: "13px",
                  fontWeight: "700",
                  marginBottom: "0px",
                }}
              >
                {tooltip_title}
              </p>
              <p
                style={{
                  fontSize: "13px",
                  fontWeight: "700",
                  marginBottom: "0px",
                }}
              >
                {tooltip}
              </p>
            </Tooltip>
          }
        >
          <div>
            <span className="count-porto">
              {!props.loading
                ? props.data_count[obj_title] == ""
                  ? "-"
                  : props.data_count[obj_title] == "-"
                  ? "-"
                  : obj_title === "electricity_used"
                  ? Number(
                      parseInt(props.data_count[obj_title])
                    ).toLocaleString()
                  : Number(props.data_count[obj_title]).toLocaleString()
                : "-"}
            </span>

            <span className="sign-porto">
              {sign} {sign === "m" && <sup>3</sup>}
            </span>
          </div>
        </OverlayTrigger>
      </div>
    );
  };

  const HeaderList = () => {
    return (
      <div
        style={{
          background: theme == "light" ? "white" : "#000",
          border: "1px solid #dddddd",
          marginTop: 15,
          width: isMobile ? "90%" : "100%",
        }}
      >
        <div className={`header_list ${theme}`}>
          {headerData.map((_) => (_.tooltip ? CardWithToolTip(_) : Card(_)))}
        </div>
        {new Date(selectdDatePicker).setHours(0, 0, 0, 0) ===
          new Date().setHours(0, 0, 0, 0)}
      </div>
    );
  };

  let titles = [
    "Cooling profile (RT)",
    "Chilled water supply temperature",
    "Space temperature & RH",
  ];

  const subtractDate = () => {
    let from_date = moment(selectdDatePicker).format("YYYY-MM-DD");
    let to_date = moment(selectdDatePicker).format("YYYY-MM-DD");
    if (previousDate == "Month") {
      from_date = moment(selectdDatePicker)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker)
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
    } else if (previousDate == "Year") {
      from_date = moment(selectdDatePicker)
        .subtract(1, "years")
        .startOf("year")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker)
        .subtract(1, "years")
        .endOf("year")
        .format("YYYY-MM-DD");
    }

    setPreviousCalendar({
      from_date,
      to_date,
    });
    localStorage.setItem("selectedDate", new Date(from_date));
    setSelectedDatePicker(new Date(from_date));

    dispatch(fetchDataCount(from_date, to_date, selectedSiteNames));
  };

  const nextDate = () => {
    let from_date = moment(selectdDatePicker).format("YYYY-MM-DD");
    let to_date = moment(selectdDatePicker).format("YYYY-MM-DD");
    if (previousDate == "Month") {
      from_date = moment(selectdDatePicker)
        .add(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker)
        .add(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
    } else if (previousDate == "Year") {
      from_date = moment(selectdDatePicker)
        .add(1, "years")
        .startOf("year")
        .format("YYYY-MM-DD");
      to_date = moment(selectdDatePicker)
        .add(1, "years")
        .endOf("year")
        .format("YYYY-MM-DD");
    }
    setPreviousCalendar({
      from_date,
      to_date,
    });
    localStorage.setItem("selectedDate", new Date(from_date));
    setSelectedDatePicker(new Date(from_date));
    dispatch(fetchDataCount(from_date, to_date, selectedSiteNames));
  };

  let shouldDisplaySpaceChart =
    props.AuthDetail &&
    props.AuthDetail.sites &&
    props.AuthDetail.sites.find((user) => {
      if (user.name === "7000amk") {
        return false;
      }
      if (user.name === "ecs") {
        return false;
      }
      return true;
    });
  return (
    <div className="data_container">
      {props.loading && (
        <Modal
          show={props.loading}
          data-backdrop="static"
          data-keyboard="false"
          className="loader"
        >
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </Modal>
      )}
      {/* <div> */}

      <div className={`previous_date_container ${theme}`}>
        {isMobile ? (
          <div className="prev_dropdown_date">
            <Dropdown
              onSelect={(e) => {
                setPreviousCalendar({
                  from_date: moment(new Date()).format("YYYY-MM-DD"),
                  to_date: moment(new Date()).format("YYYY-MM-DD"),
                });
                localStorage.setItem("selectedDateMethod", e);
                setPreviousDate(e);
              }}
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {previousDate == "Month"
                  ? "Month"
                  : previousDate == "Year"
                  ? "Year"
                  : null}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="Month">Month</Dropdown.Item>
                <Dropdown.Item eventKey="Year"> Year</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) : (
          <div className="previous_date_selector_porto">
            <div
              onClick={() => {
                setPreviousCalendar({
                  from_date: moment(new Date()).format("YYYY-MM-DD"),
                  to_date: new Date(),
                });
                localStorage.setItem("selectedDateMethod", "Month");
                setPreviousDate("Month");
              }}
              className={` ${previousDate == "Month" && "active"}`}
            >
              Month
            </div>
            <div
              onClick={() => {
                setPreviousCalendar({
                  from_date: moment(new Date()).format("YYYY-MM-DD"),
                  to_date: new Date(),
                });
                localStorage.setItem("selectedDateMethod", "Year");
                setPreviousDate("Year");
              }}
              className={` ${previousDate == "Year" && "active"}`}
            >
              Year
            </div>
          </div>
        )}
        <div
          // style={{ background: "red", width: 500 }}
          className={`calendar_selector_porto `}
        >
          <div
            onClick={() => {
              setShowSiteChanged(!showSiteChanged);
            }}
            className="select_multiple_locations "
          >
            Select Location{" "}
          </div>
          {previousDate === "Month" ? (
            <DatePicker
              onChange={onDateRangeChosen}
              customInput={<CustomCalendar />}
              selected={selectdDatePicker}
              showMonthYearPicker={previousDate == "Month" && true}
            />
          ) : (
            <DatePicker
              onChange={onDateRangeChosen}
              customInput={<CustomCalendar />}
              selected={selectdDatePicker}
              showYearPicker={previousDate == "Year" && true}
            />
          )}
        </div>
      </div>

      <div className={`${isMobile && "headerlist_container"} `}>
        {HeaderList()}
      </div>

      <div className="list_sites">
        <span className="tittle_item">{selectedSites.join(", ")}</span>
        {previousDate === "Year" && (
          <div className="d-flex">
            <div className="year-container">
              <div className="year_circle-orange-light"></div>
              <div>
                {moment(previosCalendar.from_date)
                  .subtract(1, "year")
                  .format("YYYY")}
              </div>
            </div>
            <div className="year-container">
              <div className="year_circle-orange"></div>
              <div>{moment(previosCalendar.from_date).format("YYYY")}</div>
            </div>
          </div>
        )}
      </div>

      {previousDate !== "Day" ? (
        <PreChartIndex />
      ) : (
        titles.map((title) =>
          title === "Space temperature & RH" ? (
            (getCurrentSite() === "insead" ||
              (props.data_count?.graph?.space_list?.length > 0 &&
                props.data_count?.show_space_temp)) && (
              <SpaceTempatureChart
                title={title}
                shouldDisplaySpaceChart={shouldDisplaySpaceChart}
              />
            )
          ) : title === "Chilled water supply temperature" ? (
            <ChartTwo title={title} />
          ) : (
            <ChartOne title={title} />
          )
        )
      )}

      <Modal
        show={showSiteChanged}
        className="medium-modal justify-content-center align-items-center px-4"
      >
        <Modal.Header
          closeButton
          className={`${theme === "light" ? "" : "dark dark-blk"}`}
          onHide={function () {
            setShowSiteChanged(false);
          }}
        >
          <Modal.Title>Select location</Modal.Title>
        </Modal.Header>
        {ErrorMaxSitesSelected && (
          <div className="bg-i mx-2 flex text-center red-background text-danger bold">
            Please select at least one site.
          </div>
        )}

        <hr style={{ margin: "0px" }}></hr>

        <Modal.Body
          className={`change_site_container ${
            theme === "light" ? "" : "dark dark-blk"
          }`}
        >
          <div className="change_site_card">
    
            {props.AuthDetail.company.map((item, i) => {
              return (
                <div key={i} className="site-checkbox">
                  <input
                    className="mx-2"
                    type="checkbox"
                    id={`checkbox-${i}`} // Assign a unique id for each checkbox
                    checked={selectedSites.includes(item.title)}
                    onChange={() => handleCheckboxChange(item)} // Trigger the handler for this specific site
                  />
                  <label htmlFor={`checkbox-${i}`}>{item.title}</label>{" "}
                  {/* Connect the label with the checkbox */}
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <br></br>

        <div className="d-flex mx-2 justify-content-left">
          <Button
            variant="outline-secondary"
            className="   mb-4 mx-2"
            onClick={() => {
              if (selectedSiteNames.length === 0) {
                setErrorMaxSitesSelected(true); // Show error message if no site is selected
                return; // Exit the function
              }

              setShowSiteChanged(false);
              setErrorMaxSitesSelected(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="btnConfirmSite  mb-4 mx-2"
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data_count: state.data.data_count,
  loading: state.data.loading,
  AuthDetail: state.loginUserDetail.userDetail,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDataCount: (from_date, to_date) =>
    dispatch(fetchDataCount(from_date, to_date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
